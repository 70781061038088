<template>
  <el-dialog
    :title="(objectType === 'add' ? '添加' : '编辑') + '评估对象'"
    :visible.sync="dialogVisible"
    width="750px"
    :before-close="handleClose"
  >
    <el-form :model="submitForm" :rules="rules" ref="submitForm" label-width="200px" class="demo-submitForm">
      <el-form-item label="评估对象（人员姓名）" prop="userName">
        <el-input class="w300px" placeholder="请输入" v-model="submitForm.userName"></el-input>
      </el-form-item>
      <el-form-item label="手机" prop="phone">
        <el-input class="w300px" placeholder="请输入" v-model="submitForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-select class="w300px" v-model="submitForm.sex" placeholder="请选择">
          <el-option label="女" :value="0"></el-option>
          <el-option label="男" :value="1"></el-option>
          <el-option label="未知" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出生日期" prop="birthday">
        <el-date-picker class="w300px" value-format="yyyy-MM-dd" v-model="submitForm.birthday" type="date" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="家庭地址" prop="homeAddress">
        <el-input class="w300px" placeholder="请输入" v-model="submitForm.homeAddress"></el-input>
      </el-form-item>
      <el-form-item label="评估员" prop="evaluationUserName">
        <el-autocomplete
          v-model="submitForm.evaluationUserName"
          class="w300px"
          :fetch-suggestions="querySearchAsync"
          placeholder="请输入"
          @select="handleSelect"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="doSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
// import { evaluationUser } from '@/api/evaluationProject'
import { deepClone } from '@/utils/index'
export default {
  // 添加评估对象
  name: 'EvaluationObjectHandle',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    objectType: {
      type: String,
      default: 'add'
    },
    currentObject: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    currentObject: {
      handler(val) {
        if (val && val.id) {
          const data = deepClone(val)
          console.log(val, this.objectType, 'watch')
          this.submitForm = Object.assign(data)
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    var checkPhoneValid = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码'))
      } else {
        const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      rules: {
        userName: [{ required: true, message: '请输入评估对象姓名', trigger: 'blur' }],
        phone: [{ required: true, validator: checkPhoneValid, trigger: 'blur' }],
        birthday: [{ required: true, message: '请选择出生日期', trigger: 'change' }]
      },
      submitForm: {
        birthday: '', // 出生日期
        evaluationUserId: null, // 评估员ID
        evaluationUserName: '', // 评估员名称
        homeAddress: '', // 家庭地址
        phone: null, // 手机号
        sex: null, // 性别: 0女 1男 2未知
        userName: '' // 评估对象姓名
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:dialogVisible', false)
      this.$refs.submitForm.resetFields()
    },
    querySearchAsync(queryString, cb) {
      const result = []
      evaluationUser({ name: queryString }).then(res => {
        if (res.data.length) {
          res.data.forEach(item => {
            result.push({
              value: item.evaluatorName,
              id: item.id
            })
          })
        }
      })
      cb(result)
    },
    handleSelect(val) {
      this.submitForm.evaluationUserId = val.id || null
      this.submitForm.evaluationUserName = val.value || null
    },
    doSubmit() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          if (this.objectType === 'add') {
            this.$emit('addObject', { ...this.submitForm, id: this.submitForm.id ? this.submitForm.id : Math.random() })
          } else {
            this.$emit('editObject', this.submitForm)
          }
          this.handleClose()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style></style>
